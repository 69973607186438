/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export default {
  sales: {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri"],
    datasets: { label: "Mobile apps", data: [50, 150, 300, 320, 500, 350, 200, 230, 500] },
  },
  tasks: {
    labels: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    datasets: {
      label: "Desktop apps",
      data: [
        50, 40, 100, 220, 300, 350, 400, 430, 500, 510, 550, 600, 650, 660, 680, 700, 750, 850, 950,
        1000,
      ],
    },
  },
};
